<template>
  <v-container>
    <Loader/>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h1 class="black--text text-center">Register as a Client at TCC ADR Centre</h1>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-form
            class="custom_form"
            ref="form"
            v-model="validationRules.valid"
            lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Profile/Personal Details</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.first_name" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="First Name *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.middle_name" :counter="50"
                            maxLength="50"
                            :rules="validationRules.nameRules"
                            label="Middle Name *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.last_name"
                            maxLength="50"
                            :counter="50"
                            :rules="validationRules.nameRules"
                            label="Last Name *"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="editedItem.email" :counter="100"
                            maxLength="100"
                            clearable
                            outlined
                            :rules="validationRules.emailRules"
                            dense label="Email *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="editedItem.phone" :counter="20"
                            @keypress="isNumber($event)"
                            maxLength="20"
                            clearable
                            outlined
                            :rules="validationRules.phoneRules"
                            dense label="Phone *"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="editedItem.password" :counter="20"
                            maxLength="20"
                            clearable
                            outlined
                            dense
                            :rules="validationRules.passwordRules"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show" label="Password"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field outlined
                            dense :counter="20"
                            :append-icon="showC ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showC ? 'text' : 'password'"
                            @click:append="showC = !showC"
                            name="new_password_confirmation"
                            v-model="editedItem.password_confirmation"
                            :rules="validationRules.newPasswordConfirmationRules.concat(passwordConfirmationRule)"
                            label="Confirm Password"
                            required></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!validationRules.valid" class="btn btn-primary" dark text @click="saveItem(editedItem)">
          Register
        </v-btn>
      </v-card-actions>
      <v-col cols="12">
        <div v-if="errors!=undefined && errors.length >= 0"
             role="alert"
             v-bind:class="{ show: errors.length }"
             class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import Loader from "@/view/content/Loader";
import {CLEAR_AUTH_ERRORS, REGISTER} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: 'register-form',
  components: {
    Loader,
  },
  data() {
    return {
      editedItem: {},
      show: false,
      showC: false,
      validationRules: {
        valid: true,
        nameRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 50) || "Must be less than or equal to 50 characters"
        ],
        midRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 100) || "Must be less than or equal to 100 characters"
        ],
        degRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 100) || "Must be less than or equal to 100 characters"
        ],
        uniRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 200) || "Must be less than or equal to 200 characters"
        ],
        ageRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 1) || "Must be greater than or equal to 1 characters",
          v => (v && v.length <= 3) || "Must be less than or equal to 3 characters"
        ],
        phoneRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 20) || "Must be less than or equal to 20 characters"
        ],
        genderRules: [
          v => !!v || "This field is required"
        ],
        sourceRules: [
          v => !!v || "This field is required"
        ],
        emailRules: [
          v => !!v || "This field  is required",
          v => (v && v.length <= 100) || "Must be less than 100 characters",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        selectRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'This field is required';
            else return true;
          }
        ],
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length <= 20) || "Password must be less than or equal to 20 characters",
          v => (v && v.length >= 6) || "Password must be at least 6 characters"
        ],
        newPasswordConfirmationRules: [
          v => !!v || "Type confirm password"
        ],
      },
    }
  },
  watch: {},
  mounted() {
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(CLEAR_AUTH_ERRORS);
        // save the record
        this.$store.dispatch(REGISTER, {data: item}).then((data) => {
          Swal.fire('', data._metadata.message, 'success').then(() => {
            this.$router.push('client-login')
          })
        })
      }
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    passwordConfirmationRule() {
      return () =>
          this.editedItem.password === this.editedItem.password_confirmation || "Password must match";
    }
  }
};
</script>